import {
    GET_COMPANY_USER_START,
    GET_COMPANY_USER_SUCCESS,
    GET_COMPANY_USER_FAILURE,
    CREATE_COMPANY_USER_START,
    CREATE_COMPANY_USER_SUCCESS,
    CREATE_COMPANY_USER_FAILURE,
    EDIT_COMPANY_USER_START,
    EDIT_COMPANY_USER_SUCCESS,
    EDIT_COMPANY_USER_FAILURE,
    DELETE_COMPANY_USER_START,
    DELETE_COMPANY_USER_SUCCESS,
    DELETE_COMPANY_USER_FAILURE
  } from '../../actiontypes'
  // **  Initial State
  const initialState = {
    companyUsers: [],
    isFetching: false,
    errMsg: ''
  }

  const companyUserReducer = (state = initialState, { payload, type }) => {
    switch (type) {
      case GET_COMPANY_USER_START:
        return {
          ...state,
          isFetching: true
        }
      case GET_COMPANY_USER_SUCCESS:
        return {
          ...state,
          companyUsers: payload,
          isFetching: false
        }
        

      case GET_COMPANY_USER_FAILURE:
        return { ...state, errMsg: payload.errMsg, isFetching: false }
  
      // Handle creating single COMPANY_USER unit
      case CREATE_COMPANY_USER_START:
        return {
          ...state,
          isFetching: true
        }
      case CREATE_COMPANY_USER_SUCCESS:
        return {
          ...state,
          companyUsers: [payload.data, ...state?.companyUsers],
          isFetching: false
        }
      case CREATE_COMPANY_USER_FAILURE:
        return { ...state, errMsg: payload.errMsg, isFetching: false }
  
      // Handle editing single companyUser
      case EDIT_COMPANY_USER_START:
        return {
          ...state,
          isFetching: true
        }
      case EDIT_COMPANY_USER_SUCCESS: {
        const itemIndex = state.companyUsers.findIndex((item) => item._id === payload?.data?._id)
        const newArray = [...state.companyUsers]
        newArray[itemIndex] = payload?.data
        return { ...state, companyUsers: newArray, isFetching: false }
      }
      case EDIT_COMPANY_USER_FAILURE:
        return { ...state, errMsg: payload.errMsg, isFetching: false }
  
      // Handle deleting single companyUser
      case DELETE_COMPANY_USER_START:
        return {
          ...state,
          isFetching: true
        }
      case DELETE_COMPANY_USER_SUCCESS: {
        const newArray = state.companyUsers.filter((item) => item._id !== payload.id)
        return { ...state, companyUsers: newArray, isFetching: false }
      }
      case DELETE_COMPANY_USER_FAILURE:
        return { ...state, errMsg: payload.errMsg, isFetching: false }
      default:
        return state
    }
  }

  export default companyUserReducer
  