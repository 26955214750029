// ** Redux Imports
import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

// ** Reducers Imports
import auth from './auth'
import user from './userReducer'
import navbar from './navbar'
import layout from './layout'
import product from './product'
import weightunit from './weightunit'
import brand from './brand'
import place from './place'
import routePlans from './routePlans'
import companyUser from './companyUser'
import currency from './currency'
import company from './company'
import role from './role/role'
import accessScreen from './accessScreen/accessScreen'
import email from './emailTemplates'
import order from './order'
import customer from './customer'
import contact from './contact'
import category from './category'
import modal from './modalReducer'


const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth']
}

const rootReducer = combineReducers({
  accessScreen,
  role, 
  auth,
  user,
  navbar,
  layout,
  product,
  weightunit,
  brand,
  currency,
  company,
  email,
  order,
  customer,
  contact,
  category,
  place,
  modal,
  routePlans,
  companyUser
})

export default persistReducer(persistConfig, rootReducer)
