import {
    GET_ROUTE_PLAN_START,
    GET_ROUTE_PLAN_SUCCESS,
    GET_ROUTE_PLAN_FAILURE,
    CREATE_ROUTE_PLAN_START,
    CREATE_ROUTE_PLAN_SUCCESS,
    CREATE_ROUTE_PLAN_FAILURE,
    EDIT_ROUTE_PLAN_START,
    EDIT_ROUTE_PLAN_SUCCESS,
    EDIT_ROUTE_PLAN_FAILURE,
    DELETE_ROUTE_PLAN_START,
    DELETE_ROUTE_PLAN_SUCCESS,
    DELETE_ROUTE_PLAN_FAILURE
  } from '../../actiontypes'
  // **  Initial State
  const initialState = {
    routePlans: [],
    isFetching: false,
    errMsg: ''
  }
  
  const routePlanReducer = (state = initialState, { payload, type }) => {
    switch (type) {
      // Handle getting all ROUTE_PLAN units
      case GET_ROUTE_PLAN_START:
        return {
          ...state,
          isFetching: true
        }
      case GET_ROUTE_PLAN_SUCCESS:
        return {
          ...state,
          routePlans: payload?.data,
          isFetching: false
        }

      case GET_ROUTE_PLAN_FAILURE:
        return { ...state, errMsg: payload.errMsg, isFetching: false }
  
      // Handle creating single ROUTE_PLAN unit
      case CREATE_ROUTE_PLAN_START:
        return {
          ...state,
          isFetching: true
        }
        case CREATE_ROUTE_PLAN_SUCCESS: {
          const updatedRoutePlans = [payload.data, ...state.routePlans]  
          return {
            ...state,
            routePlans: updatedRoutePlans.flat(), 
            isFetching: false
          } 
        }
      case CREATE_ROUTE_PLAN_FAILURE:
        return { ...state,
           errMsg: payload.errMsg,
            isFetching: false }
  
      // Handle editing single routePlan
      case EDIT_ROUTE_PLAN_START:
        return {
          ...state,
          isFetching: true
        }
      case EDIT_ROUTE_PLAN_SUCCESS: {
        const itemIndex = state.routePlans.findIndex((item) => item._id === payload?.data?._id)
        const newArray = [...state.routePlans]
        newArray[itemIndex] = payload?.data
        return { ...state, routePlans: newArray, isFetching: false }
      }
      case EDIT_ROUTE_PLAN_FAILURE:
        return { ...state, errMsg: payload.errMsg, isFetching: false }
  
      // Handle deleting single routePlan
      case DELETE_ROUTE_PLAN_START:
        return {
          ...state,
          isFetching: true
        }
        case DELETE_ROUTE_PLAN_SUCCESS: {
          const newArray = state.routePlans.filter((item) => item._id !== payload.id)
          return { ...state, routePlans: newArray, isFetching: false }
        }
        
      case DELETE_ROUTE_PLAN_FAILURE:
        return { ...state, errMsg: payload.errMsg, isFetching: false }
      default:
        return state
    }
  }

  export default routePlanReducer
  