import {
    GET_PLACE_START,
    GET_PLACE_SUCCESS,
    GET_PLACE_FAILURE,
    CREATE_PLACE_START,
    CREATE_PLACE_SUCCESS,
    CREATE_PLACE_FAILURE,
    EDIT_PLACE_START,
    EDIT_PLACE_SUCCESS,
    EDIT_PLACE_FAILURE,
    DELETE_PLACE_START,
    DELETE_PLACE_SUCCESS,
    DELETE_PLACE_FAILURE
  } from '../../actiontypes'
  // **  Initial State
  const initialState = {
    places: [],
    isFetching: false,
    errMsg: '',
    placesCount: 0
  }
  
  const placeReducer = (state = initialState, { payload, type }) => {
    switch (type) {
      // Handle getting all places
      case GET_PLACE_START:
        return {
          ...state,
          isFetching: true
        }
      case GET_PLACE_SUCCESS:
        return {
          ...state,
          places: payload?.data,
          placesCount: payload?.count,
          isFetching: false
        }
      case GET_PLACE_FAILURE:
        return { ...state, errMsg: payload.errMsg, isFetching: false }
  
      // Handle creating single place
      case CREATE_PLACE_START:
        return {
          ...state,
          isFetching: true
        }
      case CREATE_PLACE_SUCCESS:
        return {
          ...state,
          places: [payload.data, ...state?.places],
          isFetching: false
        }
      case CREATE_PLACE_FAILURE:
        return { ...state, errMsg: payload.errMsg, isFetching: false }
  
      // Handle editing single place
      case EDIT_PLACE_START:
        return {
          ...state,
          isFetching: true
        }
      case EDIT_PLACE_SUCCESS: {
        const itemIndex = state.places.findIndex((item) => item._id === payload?.data?._id)
        const newArray = [...state.places]
        newArray[itemIndex] = payload?.data
        return { ...state, places: newArray, isFetching: false }
      }
      case EDIT_PLACE_FAILURE:
        return { ...state, errMsg: payload.errMsg, isFetching: false }
  
      // Handle deleting single place
      case DELETE_PLACE_START:
        return {
          ...state,
          isFetching: true
        }
      case DELETE_PLACE_SUCCESS: {
        const newArray = state.places.filter((item) => item._id !== payload.id)
        return { ...state, places: newArray, isFetching: false }
      }
      case DELETE_PLACE_FAILURE:
        return { ...state, errMsg: payload.errMsg, isFetching: false }
      default:
        return state
    }
  }
  
  export default placeReducer
  